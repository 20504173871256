// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/sprite/svg/bg-art/arrow-right.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/sprite/svg/bg-art/arrow-left.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\nhtml[dir=rtl] .banner-container[data-v-f2df7722]{\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}\n.banner-container[data-v-f2df7722],html[dir=ltr] .banner-container[data-v-f2df7722]{\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")\n}\n.banner-container[data-v-f2df7722]{\n  display:grid;\n  grid-template-columns:repeat(1, minmax(0, 1fr));\n  row-gap:1rem;\n  background-repeat:no-repeat;\n}\n[dir=\"ltr\"] .banner-container[data-v-f2df7722]{\n  background-position:calc(100% + 3px) 23px;\n}\n[dir=\"rtl\"] .banner-container[data-v-f2df7722]{\n  background-position:calc(100% - (100% + 3px)) 23px;\n}\n@media (min-width: 768px){\n.banner-container[data-v-f2df7722]{\n\n    display:grid;\n\n    grid-template-columns:344px 1fr 1fr;\n\n    -moz-column-gap:90px;\n\n         column-gap:90px\n}\n[dir=\"ltr\"] .banner-container[data-v-f2df7722]{\n    background-position:calc(100% + 3px) 80px\n}\n[dir=\"rtl\"] .banner-container[data-v-f2df7722]{\n    background-position:calc(100% - (100% + 3px)) 80px\n}\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
