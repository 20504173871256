







































































import { defineComponent } from '@nuxtjs/composition-api';
import { useCustomerLoyaltyPoints } from '~/features/customer';

export default defineComponent({
  setup() {
    const {
      isFetching: isFetchingLoyaltyPoints,
      customerPoints,
      customerPointsAboutToExpire,
      customerPointsExpirationDate,
    } = useCustomerLoyaltyPoints();

    function formatDate(value: string) {
      const date = new Date(value);
      const year = date.toLocaleString('default', { year: 'numeric' });
      const month = date.toLocaleString('default', {
        month: '2-digit',
      });
      const day = date.toLocaleString('default', { day: '2-digit' });

      return [year, month, day].join('-');
    }

    return {
      isFetchingLoyaltyPoints,
      customerPoints,
      customerPointsAboutToExpire,
      customerPointsExpirationDate,
      formatDate,
    };
  },
});
