






























































import { defineComponent, nextTick, onMounted, ref, toRef, watch, PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Autoplay } from 'swiper';

export default defineComponent({
  name: 'OfferSlider',
  props: {
    items: {
      type: Array,
      required: true,
    },
    desktopSlidesPerView: {
      type: Number,
      default: 1,
    },
    mobileSlidesPerView: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: 'primary',
    },
    autoPlay: {
      type: Number,
      default: 0,
    },
    offsetBefore: {
      type: Number,
      default: 0,
    },
    disableNavigation: {
      type: Boolean,
      default: false,
    },
    placeholderImage: {
      type: Object,
      default: () => ({
        image: '/images/offer-banner-placeholder.jpg',
        mobileImage: '/images/offer-banner-placeholder.jpg',
        href: 'placeholder',
      }),
    },
    scrollDirection: {
      type: String as PropType<'horizontal' | 'vertical'>,
      default: 'horizontal',
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const items = toRef(props, 'items');
    const isSliderLoaded = ref(false);
    const currentSlide = ref(1);
    const placeholderItem = toRef(props, 'placeholderImage');

    let swiperInstance: Swiper | null = null;

    onMounted(async () => {
      const { default: Swiper } = await import('swiper');
      Swiper.use([Autoplay]);
      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      swiperInstance = new Swiper(swiperRef.value || '', {
        slidesPerView: props.mobileSlidesPerView,
        direction: props.scrollDirection,
        spaceBetween: 20,
        centeredSlides: false,
        centerInsufficientSlides: true,
        loop: false,
        slidesOffsetBefore: props.offsetBefore || 0,
        autoplay: props.autoPlay
          ? {
              delay: props.autoPlay,
            }
          : false,
        breakpoints: {
          '400': {
            slidesPerView: props.mobileSlidesPerView,
            spaceBetween: 20,
            loop: false,
          },
          '768': {
            slidesOffsetBefore: 0,
            slidesPerView: props.desktopSlidesPerView,
            spaceBetween: 20,
            loop: false,
          },
        },

        on: {
          slideChange() {
            currentSlide.value = (swiperInstance?.realIndex || 0) + 1;
          },
        },
      });

      watch(items, () => {
        swiperInstance?.updateSlides();
      });
    });

    function nextSlide() {
      swiperInstance?.slideNext();
    }

    function prevSlide() {
      swiperInstance?.slidePrev();
    }

    return {
      swiperRef,
      isSliderLoaded,
      nextSlide,
      prevSlide,
      currentSlide,
      placeholderItem,
    };
  },
});
