
















import { PropType } from '@nuxtjs/composition-api';

export default {
  name: 'MainTitle',
  props: {
    variant: {
      type: String as PropType<'' | 'white' | 'minimal'>,
      default: '',
    },
    align: {
      type: String as PropType<'center' | 'left'>,
      default: 'center',
    },
  },
};
